var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.sendMessage = void 0;
  /**
   * A basic function for wrapping the sending of postMessages to frames.
   */
  function sendMessage(frame, payload, origin) {
    try {
      frame.postMessage(payload, origin);
    } catch (error) {
      /* ignored */
    }
  }
  exports.sendMessage = sendMessage;
  return exports;
}