import { dew as _Dew } from "./";
var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.detach = exports.attach = void 0;
  var _1 = _Dew();
  var isAttached = false;
  function attach() {
    if (isAttached || typeof window === "undefined") {
      return;
    }
    isAttached = true;
    window.addEventListener("message", _1.onMessage, false);
  }
  exports.attach = attach;
  // removeIf(production)
  function detach() {
    isAttached = false;
    window.removeEventListener("message", _1.onMessage, false);
  }
  exports.detach = detach;
  // endRemoveIf(production)
  return exports;
}