import { dew as _libDew } from "./lib";
var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.Framebus = void 0;
  var lib_1 = _libDew();
  var DefaultPromise = typeof window !== "undefined" && window.Promise;
  var Framebus = /** @class */function () {
    function Framebus(options) {
      if (options === void 0) {
        options = {};
      }
      this.origin = options.origin || "*";
      this.channel = options.channel || "";
      this.verifyDomain = options.verifyDomain;
      // if a targetFrames configuration is not passed in,
      // the default behavior is to broadcast the payload
      // to the top level window or to the frame itself.
      // By default, the broadcast function will loop through
      // all the known siblings and children of the window.
      // If a targetFrames array is passed, it will instead
      // only broadcast to those specified targetFrames
      this.targetFrames = options.targetFrames || [];
      this.limitBroadcastToFramesArray = Boolean(options.targetFrames);
      this.isDestroyed = false;
      this.listeners = [];
      this.hasAdditionalChecksForOnListeners = Boolean(this.verifyDomain || this.limitBroadcastToFramesArray);
    }
    Framebus.setPromise = function (PromiseGlobal) {
      Framebus.Promise = PromiseGlobal;
    };
    Framebus.target = function (options) {
      return new Framebus(options);
    };
    Framebus.prototype.addTargetFrame = function (frame) {
      if (!this.limitBroadcastToFramesArray) {
        return;
      }
      this.targetFrames.push(frame);
    };
    Framebus.prototype.include = function (childWindow) {
      if (childWindow == null) {
        return false;
      }
      if (childWindow.Window == null) {
        return false;
      }
      if (childWindow.constructor !== childWindow.Window) {
        return false;
      }
      lib_1.childWindows.push(childWindow);
      return true;
    };
    Framebus.prototype.target = function (options) {
      return Framebus.target(options);
    };
    Framebus.prototype.emit = function (eventName, data, reply) {
      if (this.isDestroyed) {
        return false;
      }
      var origin = this.origin;
      eventName = this.namespaceEvent(eventName);
      if ((0, lib_1.isntString)(eventName)) {
        return false;
      }
      if ((0, lib_1.isntString)(origin)) {
        return false;
      }
      if (typeof data === "function") {
        reply = data;
        data = undefined; // eslint-disable-line no-undefined
      }
      var payload = (0, lib_1.packagePayload)(eventName, origin, data, reply);
      if (!payload) {
        return false;
      }
      if (this.limitBroadcastToFramesArray) {
        this.targetFramesAsWindows().forEach(function (frame) {
          (0, lib_1.sendMessage)(frame, payload, origin);
        });
      } else {
        (0, lib_1.broadcast)(payload, {
          origin: origin,
          frame: window.top || window.self
        });
      }
      return true;
    };
    Framebus.prototype.emitAsPromise = function (eventName, data) {
      var _this = this;
      return new Framebus.Promise(function (resolve, reject) {
        var didAttachListener = _this.emit(eventName, data, function (payload) {
          resolve(payload);
        });
        if (!didAttachListener) {
          reject(new Error("Listener not added for \"".concat(eventName, "\"")));
        }
      });
    };
    Framebus.prototype.on = function (eventName, originalHandler) {
      if (this.isDestroyed) {
        return false;
      }
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      var self = this;
      var origin = this.origin;
      var handler = originalHandler;
      eventName = this.namespaceEvent(eventName);
      if ((0, lib_1.subscriptionArgsInvalid)(eventName, handler, origin)) {
        return false;
      }
      if (this.hasAdditionalChecksForOnListeners) {
        /* eslint-disable no-invalid-this, @typescript-eslint/ban-ts-comment */
        handler = function () {
          var args = [];
          for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
          }
          // @ts-ignore
          if (!self.passesVerifyDomainCheck(this && this.origin)) {
            return;
          }
          // @ts-ignore
          if (!self.hasMatchingTargetFrame(this && this.source)) {
            return;
          }
          originalHandler.apply(void 0, args);
        };
        /* eslint-enable no-invalid-this, @typescript-eslint/ban-ts-comment */
      }
      this.listeners.push({
        eventName: eventName,
        handler: handler,
        originalHandler: originalHandler
      });
      lib_1.subscribers[origin] = lib_1.subscribers[origin] || {};
      lib_1.subscribers[origin][eventName] = lib_1.subscribers[origin][eventName] || [];
      lib_1.subscribers[origin][eventName].push(handler);
      return true;
    };
    Framebus.prototype.off = function (eventName, originalHandler) {
      var handler = originalHandler;
      if (this.isDestroyed) {
        return false;
      }
      if (this.verifyDomain) {
        for (var i = 0; i < this.listeners.length; i++) {
          var listener = this.listeners[i];
          if (listener.originalHandler === originalHandler) {
            handler = listener.handler;
          }
        }
      }
      eventName = this.namespaceEvent(eventName);
      var origin = this.origin;
      if ((0, lib_1.subscriptionArgsInvalid)(eventName, handler, origin)) {
        return false;
      }
      var subscriberList = lib_1.subscribers[origin] && lib_1.subscribers[origin][eventName];
      if (!subscriberList) {
        return false;
      }
      for (var i = 0; i < subscriberList.length; i++) {
        if (subscriberList[i] === handler) {
          subscriberList.splice(i, 1);
          return true;
        }
      }
      return false;
    };
    Framebus.prototype.teardown = function () {
      if (this.isDestroyed) {
        return;
      }
      this.isDestroyed = true;
      for (var i = 0; i < this.listeners.length; i++) {
        var listener = this.listeners[i];
        this.off(listener.eventName, listener.handler);
      }
      this.listeners.length = 0;
    };
    Framebus.prototype.passesVerifyDomainCheck = function (origin) {
      if (!this.verifyDomain) {
        // always pass this check if no verifyDomain option was set
        return true;
      }
      return this.checkOrigin(origin);
    };
    Framebus.prototype.targetFramesAsWindows = function () {
      if (!this.limitBroadcastToFramesArray) {
        return [];
      }
      return this.targetFrames.map(function (frame) {
        // we can't pull off the contentWindow
        // when the iframe is originally added
        // to the array, because if it is not
        // in the DOM at that time, it will have
        // a contentWindow of `null`
        if (frame instanceof HTMLIFrameElement) {
          return frame.contentWindow;
        }
        return frame;
      }).filter(function (win) {
        // just in case an iframe element
        // was removed from the DOM
        // and the contentWindow property
        // is null
        return win;
      });
    };
    Framebus.prototype.hasMatchingTargetFrame = function (source) {
      if (!this.limitBroadcastToFramesArray) {
        // always pass this check if we aren't limiting to the target frames
        return true;
      }
      var matchingFrame = this.targetFramesAsWindows().find(function (frame) {
        return frame === source;
      });
      return Boolean(matchingFrame);
    };
    Framebus.prototype.checkOrigin = function (postMessageOrigin) {
      var merchantHost;
      var a = document.createElement("a");
      a.href = location.href;
      if (a.protocol === "https:") {
        merchantHost = a.host.replace(/:443$/, "");
      } else if (a.protocol === "http:") {
        merchantHost = a.host.replace(/:80$/, "");
      } else {
        merchantHost = a.host;
      }
      var merchantOrigin = a.protocol + "//" + merchantHost;
      if (merchantOrigin === postMessageOrigin) {
        return true;
      }
      if (this.verifyDomain) {
        return this.verifyDomain(postMessageOrigin);
      }
      return true;
    };
    Framebus.prototype.namespaceEvent = function (eventName) {
      if (!this.channel) {
        return eventName;
      }
      return "".concat(this.channel, ":").concat(eventName);
    };
    Framebus.Promise = DefaultPromise;
    return Framebus;
  }();
  exports.Framebus = Framebus;
  return exports;
}