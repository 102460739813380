import { dew as _Dew } from "./";
var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.packagePayload = void 0;
  var _1 = _Dew();
  function packagePayload(event, origin, data, reply) {
    var packaged;
    var payload = {
      event: event,
      origin: origin
    };
    if (typeof reply === "function") {
      payload.reply = (0, _1.subscribeReplier)(reply, origin);
    }
    payload.eventData = data;
    try {
      packaged = _1.prefix + JSON.stringify(payload);
    } catch (e) {
      throw new Error("Could not stringify event: ".concat(e.message));
    }
    return packaged;
  }
  exports.packagePayload = packagePayload;
  return exports;
}