import { dew as _libDew } from "./lib";
import { dew as _framebusDew } from "./framebus";
var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  var lib_1 = _libDew();
  var framebus_1 = _framebusDew();
  (0, lib_1.attach)();
  exports = framebus_1.Framebus;
  return exports;
}