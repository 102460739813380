import { dew as _Dew } from "./";
var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.subscriptionArgsInvalid = void 0;
  var _1 = _Dew();
  function subscriptionArgsInvalid(event, fn, origin) {
    if ((0, _1.isntString)(event)) {
      return true;
    }
    if (typeof fn !== "function") {
      return true;
    }
    return (0, _1.isntString)(origin);
  }
  exports.subscriptionArgsInvalid = subscriptionArgsInvalid;
  return exports;
}