var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.isntString = void 0;
  function isntString(str) {
    return typeof str !== "string";
  }
  exports.isntString = isntString;
  return exports;
}