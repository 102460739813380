var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.subscribers = exports.childWindows = exports.prefix = void 0;
  exports.prefix = "/*framebus*/";
  exports.childWindows = [];
  exports.subscribers = {};
  return exports;
}