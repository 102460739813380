import { dew as _attachDew } from "./attach";
import { dew as _broadcastToChildWindowsDew } from "./broadcast-to-child-windows";
import { dew as _broadcastDew } from "./broadcast";
import { dew as _constantsDew } from "./constants";
import { dew as _dispatchDew } from "./dispatch";
import { dew as _hasOpenerDew } from "./has-opener";
import { dew as _isNotStringDew } from "./is-not-string";
import { dew as _messageDew } from "./message";
import { dew as _packagePayloadDew } from "./package-payload";
import { dew as _sendMessageDew } from "./send-message";
import { dew as _subscribeReplierDew } from "./subscribe-replier";
import { dew as _subscriptionArgsInvalidDew } from "./subscription-args-invalid";
import { dew as _typesDew } from "./types";
import { dew as _unpackPayloadDew } from "./unpack-payload";
var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  var __createBinding = exports && exports.__createBinding || (Object.create ? function (o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = {
        enumerable: true,
        get: function () {
          return m[k];
        }
      };
    }
    Object.defineProperty(o, k2, desc);
  } : function (o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
  });
  var __exportStar = exports && exports.__exportStar || function (m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
  };
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  __exportStar(_attachDew(), exports);
  __exportStar(_broadcastToChildWindowsDew(), exports);
  __exportStar(_broadcastDew(), exports);
  __exportStar(_constantsDew(), exports);
  __exportStar(_dispatchDew(), exports);
  __exportStar(_hasOpenerDew(), exports);
  __exportStar(_isNotStringDew(), exports);
  __exportStar(_messageDew(), exports);
  __exportStar(_packagePayloadDew(), exports);
  __exportStar(_sendMessageDew(), exports);
  __exportStar(_subscribeReplierDew(), exports);
  __exportStar(_subscriptionArgsInvalidDew(), exports);
  __exportStar(_typesDew(), exports);
  __exportStar(_unpackPayloadDew(), exports);
  return exports;
}