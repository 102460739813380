import { dew as _Dew } from "./";
var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.unpackPayload = void 0;
  var _1 = _Dew();
  function unpackPayload(e) {
    var payload;
    if (e.data.slice(0, _1.prefix.length) !== _1.prefix) {
      return false;
    }
    try {
      payload = JSON.parse(e.data.slice(_1.prefix.length));
    } catch (err) {
      return false;
    }
    if (payload.reply) {
      var replyOrigin_1 = e.origin;
      var replySource_1 = e.source;
      var replyEvent_1 = payload.reply;
      payload.reply = function reply(replyData) {
        if (!replySource_1) {
          return;
        }
        var replyPayload = (0, _1.packagePayload)(replyEvent_1, replyOrigin_1, replyData);
        if (!replyPayload) {
          return;
        }
        replySource_1.postMessage(replyPayload, replyOrigin_1);
      };
    }
    return payload;
  }
  exports.unpackPayload = unpackPayload;
  return exports;
}